<script>
import { VueContentLoading } from "vue-content-loading";

export default {
  components: { VueContentLoading },
  props: {
    loading: {
      default: true
    },
    tree: {
      default: {
        id: null,
        username: null,
        image: null
      }
    }
  }
};
</script>

<template>
  <div v-if="loading"><template><vue-content-loading :width="20" :height="20"><circle cx="10" cy="10" r="10" /></vue-content-loading></template></div>
  <div v-else>
    <div v-if="tree">
      <router-link :to="'/network/binary/'+tree.id">
        <img v-if="tree.image" :src="require('@/assets/images/plans/' + tree.image)">
        <img v-else src="@/assets/images/logo-orbyy-icon.png">
        <span class="notranslate">{{ tree.username }}</span>
      </router-link>
    </div>
    <img v-else src="@/assets/images/logo-orbyy-icon-gray.png">
  </div>
</template>